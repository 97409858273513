export const tryAsync = async <Result,>(
	func: (...args: Array<unknown>) => Result,
): Promise<
	| { success: false; error: unknown }
	| { success: true; result: Awaited<Result> }
> => {
	try {
		const result = await func();
		return { success: true, result } as const;
	} catch (error) {
		return { success: false, error } as const;
	}
};

export const trySync = <Result,>(
	func: (...args: Array<unknown>) => Result,
): { success: false; error: unknown } | { success: true; result: Result } => {
	try {
		const result = func();
		return { success: true, result } as const;
	} catch (error) {
		return { success: false, error } as const;
	}
};
